import { ref } from 'vue'
import Ably from 'ably'

export default {
  install: async (app, options) => {
    const { apiKey } = options

    try {
      const realtime = new Ably.Realtime({
        key: apiKey,
      })

      const ably = ref({
        realtime,
      })

      app.provide('ably', ably)
    } catch (err) {
      console.error('Not connected to Ably', err)
    }
  },
}
