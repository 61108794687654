<template>
  <div class="flex flex-col gap-6">
    <ManageBulkESimsShare
      v-if="shareBulkSIMs"
      :selectedBulkSims="selectedBulkSIMs"
      @hideBulkShare="hideBulkShare"
    />

    <ManageBulkESims
      v-else-if="showBulkSIMs"
      :selected-bulk-sims="selectedBulkSIMs"
      @hide-bulk-assign="hideBulkAssign"
      @show-bulk-share="showBulkShare"
      @fetch-esims="fetchSimList"
    />

    <template v-else>
      <div class="flex justify-between place-items-end">
        <ManageEsimsHeader
          v-model:search-query="searchQuery"
          v-model:date-range="dateRange"
          v-model:status-filter="filters.byAllocationStatus"
          v-model:origin-filter="filters.byOrigin"
          v-model:attention-filters-selected="filters.attentionFilters"
          data-testid="manageEsimsHeader"
          @clear-panel-filters="clearPanelFilters"
          @clear-all-filters="clearAllFilters"
        />

        <div
          v-if="selectedRows?.length"
          class="flex flex-row items-center gap-4"
        >
          <strong class="text-trail-body3">{{
            $t('numberOfESimsSelected', { number: selectedRows.length })
          }}</strong>

          <ManageEsimsTableBulkActions
            :esims="selectedRows"
            @show-bulk-assign="showBulkAssign"
            @show-bulk-share="showBulkShare"
          />
        </div>
      </div>

      <ManageEsimsTable
        v-model:selectedRows="selectedRows"
        :rows="simList"
        :is-loading="loading"
        :pagination="paginationData"
        @pagination-changed="pageChanged"
        @patch-sim="handlePatchSim"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { definePage } from 'vue-router/auto'
import { useRoute } from 'vue-router/auto'
import { cloneDeep, isEqual } from 'lodash'
import { useApiData } from '@/composables/useApiData'
import { transformSimListResponse } from '@/api/transforms/simsTransforms'
import type { SimListItem } from '@/models/simModels'
import router from '@/router'

definePage({
  name: 'manage-esims',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Sim\\Models\\Sim'],
  },
})

const route = useRoute()
const searchQuery = ref('')
const currentPage = ref(1)
const dateRange = ref('')
const showBulkSIMs = ref(false)
const shareBulkSIMs = ref(false)
const selectedBulkSIMs = ref<SimListItem[]>([])
const selectedRows = ref<[]>([])

const defaultFilters = {
  byAllocationStatus: '',
  byOrigin: '',
  attentionFilters: [],
}

const filters = ref(cloneDeep(defaultFilters))

const pageChanged = (page: number) => {
  currentPage.value = page
}

const checkICCIDWithQuery = () => {
  const iccid = route?.query?.iccid

  if (iccid) {
    searchQuery.value = iccid.toString()
    router.push('/manage-esims')
  } else {
    fetchSimList()
  }
}

const showBulkAssign = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  showBulkSIMs.value = true
}

const showBulkShare = (esims: SimListItem[]) => {
  selectedRows.value = []
  selectedBulkSIMs.value = esims
  shareBulkSIMs.value = true
}

const hideBulkAssign = () => {
  showBulkSIMs.value = false
}

const hideBulkShare = () => {
  shareBulkSIMs.value = false
}

const {
  data: simListResponse,
  fetchData: fetchSimList,
  loading,
} = useApiData(`manage/v1/sims`, {
  transformer: transformSimListResponse,
  params: () => ({
    'filter[search]': searchQuery.value,
    'filter[created_at]': dateRange.value.replace(' to ', ' - '),
    'filter[type]': filters?.value?.byAllocationStatus,
    'filter[origin]': filters.value.byOrigin,
    'filter[attention]': filters.value.attentionFilters,
    page: currentPage.value,
    limit: 25,
  }),
  immediate: false,
})

const simList = computed(() => simListResponse.value?.data ?? [])
const paginationData = computed(() => simListResponse.value?.meta)

const handlePatchSim = (rowId: number, data: Partial<SimListItem>) => {
  const simListItem = simList.value.find(({ id }) => id === rowId)!
  simListItem.endUser = data.endUser
  simListItem.companyBrandId = data.companyBrandId
  simListItem.companyBrandAlias = data.companyBrandAlias
}

const clearPanelFilters = () => {
  if (!isEqual(filters.value, defaultFilters))
    filters.value = cloneDeep(defaultFilters)
}

const clearAllFilters = () => {
  filters.value = cloneDeep(defaultFilters)
  searchQuery.value = ''
  dateRange.value = ''
}

onMounted(() => {
  checkICCIDWithQuery()
})

watch(
  [searchQuery, dateRange, filters],
  () => {
    currentPage.value = 1
  },
  { deep: true }
)

watch(
  [searchQuery, dateRange, filters, currentPage],
  () => {
    fetchSimList()
  },
  { deep: true }
)
</script>
