<template>
  <div class="grid gap-4">
    <h3 class="text-trail-title2 text-trail-text-title-normal">
      {{ $t('userPageTitle') }}
      <span v-if="paginationData?.total">({{ paginationData.total }})</span>
    </h3>

    <div class="grid gap-4">
      <div
        class="flex items-center z-30 justify-between"
        :class="{
          '!items-start flex-col gap-4': width < 850,
        }"
      >
        <div class="flex gap-4">
          <TableSearchInput
            :recently-visited-storage-key="recentlyVisitedStorageKey"
            search-placeholder="userTableSearchPlaceholder"
            @search-changed="onSearchChanged"
          />

          <UserFilters @filters-changed="onFiltersChanged" />
        </div>

        <UserForm
          v-if="permissionsStore.canAddUser"
          :fetch-users="fetchData"
          :selected-users="selectedRows"
          :isSSOEnabled="isSSOEnabled"
          @clear-selected-users="clearSelectedUsers"
        />
      </div>

      <TrailBanner
        v-if="isSSOEnabled"
        class="mb-2"
        type="warning"
        data-testid="sso-banner"
      >
        {{ $t('ssoBannerText') }}
      </TrailBanner>
    </div>

    <UsersTable
      v-model:selectedRows="selectedRows"
      :rows="userList"
      :is-loading="loading"
      :searchQuery="searchQuery"
      :pagination="paginationData"
      @pagination-changed="pageChanged"
      @update:reload-table="fetchData"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { definePage } from 'vue-router/auto'
import { useApiData } from '@/composables/useApiData'
import { useSSOConfiguration } from '@/composables/sso/use-sso-configuration'
import type { UserFilterType } from '@/models/userModels'
import { usePermissionsStore } from '@/stores/permissions'
import { transformUserListResponse } from '@/api/transforms/userTransforms.ts'
import { useWindowSize } from '@vueuse/core'

definePage({
  name: 'users',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\User\\Models\\User'],
  },
})

const selectedRows = ref<[]>([])
const { isSSOEnabled } = useSSOConfiguration()
const searchQuery = ref('')
const currentPage = ref(1)
const urlQuery = ref({})
const { width } = useWindowSize()

const recentlyVisitedStorageKey = 'usersRecentlyVisitedStorageKey'
const permissionsStore = usePermissionsStore()

const {
  data: userListResponse,
  fetchData,
  loading,
} = useApiData('/user/v1/users', {
  transformer: transformUserListResponse,
  params: () => ({
    'filter[search]': searchQuery.value,
    ...urlQuery.value,
    page: currentPage.value,
    limit: 25,
  }),
  immediate: true,
})

const pageChanged = (page: number) => {
  selectedRows.value = []
  currentPage.value = page
  fetchData()
}

const clearSelectedUsers = () => {
  selectedRows.value = []
  fetchData()
}

const onSearchChanged = (search: string) => {
  selectedRows.value = []
  searchQuery.value = search
  currentPage.value = 1
  fetchData()
}

const onFiltersChanged = (filter: UserFilterType) => {
  urlQuery.value = { filter }
  selectedRows.value = []
  fetchData()
}

const userList = computed(() => userListResponse.value?.data ?? [])
const paginationData = computed(() => userListResponse.value?.meta)
</script>
